import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import ActForm from './act/Form.jsx';

import Footer from '../components/Footer.jsx';
import Page from '../components/Page.jsx';
import Button from '../components/Button.jsx';
import requestSuccess from '../functions/requestSuccess';

import getHeaders from '../functions/getHeaders';
import handlerLoading from '../functions/handlerLoading';
import AnimateChange from '../components/AnimateChange.jsx';

class Anket extends Page {
    constructor(props) {
        super(props);
        this.state = {};

        this.getPrivateContent = this.getPrivateContent.bind(this);

        this.parent = React.createRef();
    }

    isPrivate = true;

    pageName = 'anket';

    contentName = 'main';

    getPrivateContent() {
        axios
            .get(`${process.env.REACT_APP_API}/api/GetCabinetInfo`, {
                headers: getHeaders(),
            })
            .then(
                (res) => {
                    requestSuccess(res);

                    this.setState({
                        privateContent: res.data.data,
                        updatedKey: new Date().getTime(),
                    });
                },
                () => {},
            );
    }

    downloadPDF(pdf, name) {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement('a');
        const fileName = `${name}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    async downloadAct(e) {
        e.preventDefault();

        const { levels } = this.props;
        const id = levels[1];

        await handlerLoading.call(this, 'download', { error: null });

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/GetAct`,
                { userPrizeId: id },
                { headers: getHeaders() },
            );

            requestSuccess(response);

            const pdf = response.data.data.pdf;

            this.downloadPDF(pdf, id);
        } catch (error) {
            try {
                const { errorText } = error.response.data;

                this.setState({ error: errorText });
            } catch (e2) {
                this.setState({ error: 'Ошибка сервера' });
            }
        }

        await handlerLoading.call(this, null);
    }

    componentDidMount() {
        super.componentDidMount();

        this.getPrivateContent(true);
    }

    render() {
        const { components, privateContent, loadingKey, error } = this.state;

        return (
            <>
                <div ref={this.parent} className="page _anket _inner">
                    <div className="page__section">
                        <div className="innerPageHead _outside">
                            <div className="innerPageHead__title _INNERTITLE">Запрос акта</div>
                            <p className="innerPageHead__description _white">
                                Мы проверили Ваши данные, теперь Вам необходимо скачать акт,
                                подписать его,
                                <br /> отсканировать и загрузить в&nbsp;форме ниже
                            </p>
                            <a
                                className="innerPageHead__button"
                                onClick={this.downloadAct.bind(this)}
                            >
                                <Button className="_green" loader={loadingKey === 'download'}>
                                    Скачать акт
                                </Button>
                            </a>
                            <AnimateChange
                                className="innerPageHead__error _center"
                                renderKey={error}
                                paramsParent={{
                                    width: document.querySelector('.innerPageHead')?.offsetWidth,
                                }}
                                itemParams={['width']}
                            >
                                {error ? <div className="error">{error}</div> : null}
                            </AnimateChange>
                        </div>
                    </div>
                    <div className="page__section">
                        <ActForm
                            components={components}
                            privateContent={privateContent}
                            getPrivateContent={this.getPrivateContent}
                        />
                    </div>
                    <div className="page__section _footer">
                        <Footer footer={components?.footer} />
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(Anket);

Anket.propTypes = {
    device: PropTypes.string,
    levels: PropTypes.object,
};
